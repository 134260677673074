import React, { useState, useEffect } from "react";
import { useFirestore } from "../../../firebase files/hooks/useFirestore";
import { useDocument } from "../../../firebase files/hooks/useDocument";
import { format } from "date-fns";
import Select from "react-select";

const UserDetailsVehicleDetails = ({userProfileId}) => {
  const { document: userProfileDoc, error: userProfileError } = useDocument(
    "users",
    userProfileId
  );
  const { updateDocument } = useFirestore("users");

  const [isEditing, setIsEditing] = useState(false);
  const [vehicleNumberPlate, setVehicleNumberPlate] = useState("");
  const [vehicleStatus, setVehicleStatus] = useState("true");
  const [vehicleType, setVehicleType] = useState("");
  const [drivingLicence, setDrivingLicence] = useState("");
  const [vehicleDetailUpdateMessage, setVehicleDetailUpdateMessage] =
    useState("");
  const [isVdUpdating, setIsVdUpdating] = useState(false);
  const [vdMessageType, setVdMessageType] = useState("");

  const handleCancelClick = () => {
    setIsEditing(!isEditing);
    if (userProfileDoc) {
      setVehicleNumberPlate(userProfileDoc.vehicleNumberPlate || "");
      setVehicleStatus(userProfileDoc.vehicleStatus || "");
      setVehicleType(userProfileDoc.vehicleType || "");
      setDrivingLicence(userProfileDoc.drivingLicence || "");
    }
  };

  useEffect(() => {
    if (userProfileDoc) {
      setVehicleNumberPlate(userProfileDoc.vehicleNumberPlate || "");
      setVehicleStatus(userProfileDoc.vehicleStatus || "");
      setVehicleType(userProfileDoc.vehicleType || "");
      setDrivingLicence(userProfileDoc.drivingLicence || "");
    } else {
      // Set default values for new entries
      setVehicleNumberPlate("");
      setVehicleStatus("");
      setVehicleType("");
      setDrivingLicence("");
    }
  }, [userProfileDoc]);

  const handleUpdate = async () => {
    // Validation for required fields
    if (
      vehicleStatus === "" ||
      vehicleStatus === undefined ||
      vehicleStatus === null
    ) {
      setVehicleDetailUpdateMessage("Please select vehicle status.");
      setVdMessageType("error_msg");
      setTimeout(() => {
        setVehicleDetailUpdateMessage("");
        setVdMessageType("");
      }, 4000); // Clear message after 5 seconds
      return;
    }

    const dataSet = {
      vehicleNumberPlate,
      vehicleStatus,
      vehicleType,
      drivingLicence,
    };

    setIsVdUpdating(true);
    setVehicleDetailUpdateMessage("");

    try {
      await updateDocument(userProfileId, dataSet);
      setVdMessageType("success_msg");
      setVehicleDetailUpdateMessage("Vehicle details updated successfully!");
      setTimeout(() => {
        setIsEditing(!isEditing);
      }, 4000);
    } catch (error) {
      console.error("Error updating vehicle details:", error);
      setVdMessageType("error_msg");
      setVehicleDetailUpdateMessage(
        "Failed to update vehicle details. Please try again."
      );
    } finally {
      setIsVdUpdating(false);
      setTimeout(() => {
        setVehicleDetailUpdateMessage("");
        setVdMessageType("");
      }, 4000); // Clear message after 5 seconds
    }
  };

  return (
    <div className="pd-cards animate-slide-in">
      <div className="pd-heading">
        <h2>Vehicle Details</h2>
        {isEditing ? null : (
          <div
            className="pd-edit-state"
            onClick={() => {
              setIsEditing((prev) => !prev);
            }}
          >
            <span className="material-symbols-outlined">edit</span>
            Edit
          </div>
        )}
      </div>
      <div className="pd-data">
        {!isEditing && (
          <>
            <div className="pd-data-element">
              <div className="pd-icon">
                <img src="/assets/img/steering-wheel.png" alt="" />
              </div>
              <div className="pd-data-value">
                <h6>Is Vehicle</h6>
                <p>{userProfileDoc?.vehicleStatus || "Not Yet Provide"}</p>
              </div>
            </div>
            {userProfileDoc && userProfileDoc.vehicleStatus === "yes" ? (
              <div className="pd-data-element">
                <div className="pd-icon">
                  <img
                    src={`/assets/img/${
                      userProfileDoc
                        ? userProfileDoc.vehicleType === "2-Wheeler"
                          ? "bike.png"
                          : userProfileDoc.vehicleType === "4-Wheeler"
                          ? "car.png"
                          : "vehicles.png"
                        : "vehicles.png"
                    }`}
                    alt=""
                  />
                </div>
                <div className="pd-data-value">
                  <h6>Vehicle Type</h6>
                  <p>
                    {userProfileDoc && userProfileDoc.vehicleType
                      ? userProfileDoc.vehicleType
                      : "Not provided yet"}
                  </p>
                </div>
              </div>
            ) : (
              ""
            )}
            {userProfileDoc && userProfileDoc.vehicleStatus === "yes" ? (
              <div className="pd-data-element">
                <div className="pd-icon">
                  <img src="/assets/img/numberplate.png" alt="" />
                </div>
                <div className="pd-data-value">
                  <h6>Number Plate</h6>
                  <p>
                    {userProfileDoc && userProfileDoc.vehicleNumberPlate
                      ? userProfileDoc.vehicleNumberPlate
                      : "Not provided yet"}
                  </p>
                </div>
              </div>
            ) : (
              ""
            )}
            {userProfileDoc && userProfileDoc.vehicleStatus === "yes" ? (
              <div className="pd-data-element">
                <div className="pd-icon">
                  <img src="/assets/img/id-card.png" alt="" />
                </div>
                <div className="pd-data-value">
                  <h6>Driving Licence</h6>
                  <p>
                    {userProfileDoc && userProfileDoc.drivingLicence
                      ? userProfileDoc.drivingLicence
                      : "Not provided yet"}
                  </p>
                </div>
              </div>
            ) : (
              ""
            )}
          </>
        )}
        {isEditing && (
          <div className="col mb-4">
            <div className="row row_gap">
              <div className="col-lg-4 col-md-6 col-sm-12">
                <div className="form-field">
                  <label className="input-label required-field">
                    Vehicle Status
                  </label>
                  <div className="vs-radio-btn">
                    <div className="theme_radio_container">
                      <div className="radio_single">
                        <input
                          type="radio"
                          name="status"
                          value="yes"
                          id="vehicle-yes"
                          checked={vehicleStatus === "yes"}
                          onChange={(e) => setVehicleStatus(e.target.value)}
                        />
                        <label
                          htmlFor="vehicle-yes"
                          style={{
                            padding: "12px",
                            borderRadius: "10px",
                          }}
                        >
                          Yes
                        </label>
                      </div>

                      <div className="radio_single">
                        <input
                          type="radio"
                          name="status"
                          value="no"
                          id="vehicle-no"
                          checked={vehicleStatus === "no"}
                          onChange={(e) => setVehicleStatus(e.target.value)}
                        />
                        <label
                          htmlFor="vehicle-no"
                          style={{
                            padding: "12px",
                            borderRadius: "10px",
                          }}
                        >
                          No
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {/* other fields will be only visible if vehicleStatus is "yes"  */}

              {vehicleStatus === "yes" && (
                <>
                  {/* Vehicle type  */}
                  <div className="col-lg-4 col-md-6 col-sm-12">
                    <div className="form-field">
                      <label className="input-label required-field">
                        Vehicle Type
                      </label>
                      <div className="vt-radio-btn">
                        <div className="theme_radio_container">
                          <div className="radio_single">
                            <input
                              type="radio"
                              name="type"
                              value="2-wheeler"
                              checked={vehicleType === "2-wheeler"}
                              onChange={(e) => setVehicleType(e.target.value)}
                              id="vehicle-2w"
                            />
                            <label
                              htmlFor="vehicle-2w"
                              style={{
                                padding: "12px",
                                borderRadius: "10px",
                              }}
                            >
                              2-Wheeler
                            </label>
                          </div>

                          <div className="radio_single">
                            <input
                              type="radio"
                              name="type"
                              value="4-wheeler"
                              checked={vehicleType === "4-wheeler"}
                              onChange={(e) => setVehicleType(e.target.value)}
                              id="vehicle-4w"
                            />
                            <label
                              htmlFor="vehicle-4w"
                              style={{
                                padding: "12px",
                                borderRadius: "10px",
                              }}
                            >
                              4-Wheeler
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* Number Plate  */}
                  <div className="col-lg-4 col-md-6 col-sm-12">
                    <div className="form-field">
                      <label className="input-label required-field">
                        Number Plate
                      </label>
                      <input
                        type="text"
                        value={vehicleNumberPlate}
                        onChange={(e) => setVehicleNumberPlate(e.target.value)}
                        placeholder="Enter Number Plate"
                        className="form-input"
                      />
                    </div>
                  </div>

                  {/* Driving Licence Number  */}
                  <div className="col-lg-4 col-md-6 col-sm-12">
                    <div className="form-field">
                      <label className="input-label required-field">
                        Driving Licence Number
                      </label>
                      <input
                        type="text"
                        value={drivingLicence}
                        onChange={(e) => setDrivingLicence(e.target.value)}
                        placeholder="Enter Driving Licence Number"
                        className="form-input"
                      />
                    </div>
                  </div>
                </>
              )}
            </div>
            <div className="pd-edit-option">
              {vehicleDetailUpdateMessage && (
                <p className={`edit-msg_area ${vdMessageType}`}>
                  {vehicleDetailUpdateMessage}
                </p>
              )}

              <button
                onClick={handleCancelClick}
                disabled={isVdUpdating}
                className={` pd-option-btn option-btn-cancel ${
                  isVdUpdating ? "disabled" : ""
                }`}
              >
                Cancel
              </button>
              <button
                onClick={handleUpdate}
                disabled={isVdUpdating}
                className={` pd-option-btn option-btn-save ${
                  isVdUpdating ? "disabled" : ""
                }`}
              >
                {isVdUpdating ? "Saving..." : "Save"}
              </button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default UserDetailsVehicleDetails;
