import React, { useState, useEffect } from "react";
import { useFirestore } from "../../../firebase files/hooks/useFirestore";
import { useDocument } from "../../../firebase files/hooks/useDocument";

const UserDetailsBankDetails = ({ userProfileId }) => {
  const { document: userProfileDoc, error: userProfileError } = useDocument(
    "users",
    userProfileId
  );
  const { updateDocument } = useFirestore("users");

  const [isEditing, setIsEditing] = useState(false);
  const [isBankDetailSaving, setIsBankDetailSaving] = useState(false);
  const [saveBankDetailMessage, setSaveBankDetailMessage] = useState("");
  const [BankDetailMessageType, setBankDetailMessageType] = useState("");

  const [bankDetailFormData, setBankDetailFormData] = useState({
    acHolderName: "",
    acNumber: "",
    bankName: "",
    branchName: "",
    ifscCode: "",
    upiId: "",
    qrCode: null,
  });

  // Update BankDetailFormData when userProfileDoc changes
  useEffect(() => {
    if (userProfileDoc) {
      setBankDetailFormData({
        acHolderName: userProfileDoc.bankDetail?.acHolderName || "",
        acNumber: userProfileDoc.bankDetail?.acNumber || "",
        bankName: userProfileDoc.bankDetail?.bankName || "",
        branchName: userProfileDoc.bankDetail?.branchName || "",
        ifscCode: userProfileDoc.bankDetail?.ifscCode || "",
        upiId: userProfileDoc.bankDetail?.upiId || "",
        qrCode: userProfileDoc.bankDetail?.qrCode || null,
      });
    }
  }, [userProfileDoc]);

  const handleBankDetailCancelClick = () => {
    if (userProfileDoc) {
      setBankDetailFormData({
        acHolderName: userProfileDoc.bankDetail?.acHolderName || "",
        acNumber: userProfileDoc.bankDetail?.acNumber || "",
        bankName: userProfileDoc.bankDetail?.bankName || "",
        branchName: userProfileDoc.bankDetail?.branchName || "",
        ifscCode: userProfileDoc.bankDetail?.ifscCode || "",
        upiId: userProfileDoc.bankDetail?.upiId || "",
        qrCode: userProfileDoc.bankDetail?.qrCode || null,
      });
    }
    setIsEditing(!isEditing);
  };

  // Handle Input Changes
  const handleBankDetailChange = (e) => {
    const { name, value } = e.target;
    setBankDetailFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  // Validate Fields
  const validateBankDetailForm = () => {
    if (
      !bankDetailFormData.acHolderName ||
      !bankDetailFormData.acNumber ||
      !bankDetailFormData.bankName ||
      !bankDetailFormData.ifscCode
    ) {
      setSaveBankDetailMessage("Please fill all required fields.");
      setBankDetailMessageType("error_msg");
      setTimeout(() => {
        setSaveBankDetailMessage("");
        setBankDetailMessageType("");
      }, 4000);
      return false;
    }

    return true;
  };

  // Submit Form Data
  const handleBankDetailSubmit = async () => {
    if (!validateBankDetailForm()) return;

    setIsBankDetailSaving(true);
    setSaveBankDetailMessage("");

    try {
      const updatedData = {
        ...userProfileDoc,
        bankDetail: bankDetailFormData,
      };

      await updateDocument(userProfileId, updatedData);
      setBankDetailMessageType("success_msg");
      setSaveBankDetailMessage("Updated successfully!");

      setTimeout(() => {
        setIsEditing(false);
      }, 4000);
    } catch (error) {
      console.error("Error updating bank details:", error);
      setBankDetailMessageType("error_msg");
      setSaveBankDetailMessage(
        "Failed to update bank details. Please try again."
      );
    } finally {
      setIsBankDetailSaving(false);
      setTimeout(() => {
        setSaveBankDetailMessage("");
        setBankDetailMessageType("");
      }, 4000);
    }
  };
  return (
    <div className="pd-cards animate-slide-in">
      <div className="pd-heading">
        <h2>Bank Detail</h2>
        {isEditing ? null : (
          <div
            className="pd-edit-state"
            onClick={() => {
              setIsEditing((prev) => !prev);
            }}
          >
            <span className="material-symbols-outlined">edit</span>
            Edit
          </div>
        )}
      </div>
      <div className="pd-data">
        {!isEditing && (
          <>
            <div className="pd-data-element">
              <div className="pd-icon">
                <img src="/assets/img/accountholder.png" alt="" />
              </div>
              <div className="pd-data-value">
                <h6>A/C Holder Name</h6>
                <p>
                  {userProfileDoc?.bankDetail?.acHolderName
                    ? userProfileDoc.bankDetail.acHolderName
                    : "Not provided yet"}
                </p>
              </div>
            </div>
            <div className="pd-data-element">
              <div className="pd-icon">
                <img src="/assets/img/accountnumber.png" alt="" />
              </div>
              <div className="pd-data-value">
                <h6>A/C Number</h6>
                <p>
                  {userProfileDoc?.bankDetail?.acNumber
                    ? userProfileDoc.bankDetail.acNumber
                    : "Not provided yet"}
                </p>
              </div>
            </div>
            <div className="pd-data-element">
              <div className="pd-icon">
                <img src="/assets/img/bank.png" alt="" />
              </div>
              <div className="pd-data-value">
                <h6>Bank Name</h6>
                <p>
                  {userProfileDoc?.bankDetail?.bankName
                    ? userProfileDoc.bankDetail.bankName
                    : "Not provided yet"}
                </p>
              </div>
            </div>
            <div className="pd-data-element">
              <div className="pd-icon">
                <img src="/assets/img/branch.png" alt="" />
              </div>
              <div className="pd-data-value">
                <h6>Branch Name</h6>
                <p>
                  {userProfileDoc?.bankDetail?.branchName
                    ? userProfileDoc.bankDetail.branchName
                    : "Not provided yet"}
                </p>
              </div>
            </div>
            <div className="pd-data-element">
              <div className="pd-icon">
                <img src="/assets/img/ifsccode.png" alt="" />
              </div>
              <div className="pd-data-value">
                <h6>IFSC Code</h6>
                <p>
                  {userProfileDoc?.bankDetail?.ifscCode
                    ? userProfileDoc.bankDetail.ifscCode
                    : "Not provided yet"}
                </p>
              </div>
            </div>
            <div className="pd-data-element">
              <div className="pd-icon">
                <img src="/assets/img/ifsccode.png" alt="" />
              </div>
              <div className="pd-data-value">
                <h6>UPI ID</h6>
                <p>
                  {userProfileDoc?.bankDetail?.upiId
                    ? userProfileDoc.bankDetail.upiId
                    : "Not provided yet"}
                </p>
              </div>
            </div>
          </>
        )}
        {isEditing && (
          <div className="col mb-4">
            <div className="row row_gap">
              <div className="col-lg-4 col-md-6 col-sm-12">
                <div className="form-field">
                  <label className="input-label required-field">
                    A/C Holder Name
                  </label>
                  <input
                    type="text"
                    name="acHolderName"
                    value={bankDetailFormData.acHolderName}
                    onChange={handleBankDetailChange}
                    required
                    placeholder="Type A/C holder name here"
                    onKeyPress={(e) => {
                      const regex = /^[a-zA-Z\s]*$/; // Only letters and spaces allowed
                      if (!regex.test(e.key)) {
                        e.preventDefault(); // Prevent invalid input
                      }
                    }}
                    className="form-input"
                  />
                </div>
              </div>
              <div className="col-lg-4 col-md-6 col-sm-12">
                <div className="form-field">
                  <label className="input-label required-field">
                    A/C Number
                  </label>
                  <input
                    type="number"
                    name="acNumber"
                    value={bankDetailFormData.acNumber}
                    onChange={handleBankDetailChange}
                    required
                    placeholder="Type A/C number here"
                    className="form-input"
                  />
                </div>
              </div>
              <div className="col-lg-4 col-md-6 col-sm-12">
                <div className="form-field">
                  <label className="input-label required-field">
                    Bank Name
                  </label>
                  <input
                    type="text"
                    name="bankName"
                    value={bankDetailFormData.bankName}
                    onChange={handleBankDetailChange}
                    required
                    placeholder="Type bank name here"
                    className="form-input"
                  />
                </div>
              </div>
              <div className="col-lg-4 col-md-6 col-sm-12">
                <div className="form-field">
                  <label className="input-label required-field">
                    Branch Name
                  </label>
                  <input
                    type="text"
                    name="branchName"
                    value={bankDetailFormData.branchName}
                    onChange={handleBankDetailChange}
                    required
                    placeholder="Type branch name here"
                    className="form-input"
                  />
                </div>
              </div>
              <div className="col-lg-4 col-md-6 col-sm-12">
                <div className="form-field">
                  <label className="input-label required-field">
                    IFSC Code
                  </label>
                  <input
                    type="text"
                    name="ifscCode"
                    value={bankDetailFormData.ifscCode}
                    onChange={handleBankDetailChange}
                    required
                    placeholder="Type IFSC code here"
                    className="form-input"
                  />
                </div>
              </div>
              <div className="col-lg-4 col-md-6 col-sm-12">
                <div className="form-field">
                  <label className="input-label">UPI Id</label>
                  <input
                    type="text"
                    name="upiId"
                    value={bankDetailFormData.upiId}
                    onChange={handleBankDetailChange}
                    required
                    placeholder="Type UPI ID here"
                    className="form-input"
                  />
                </div>
              </div>
            </div>
            <div className="pd-edit-option">
              {saveBankDetailMessage && (
                <p className={`edit-msg_area ${BankDetailMessageType}`}>
                  {saveBankDetailMessage}
                </p>
              )}

              <button
                onClick={handleBankDetailCancelClick}
                disabled={isBankDetailSaving}
                className={` pd-option-btn option-btn-cancel ${
                  isBankDetailSaving ? "disabled" : ""
                }`}
              >
                Cancel
              </button>
              <button
                onClick={handleBankDetailSubmit}
                disabled={isBankDetailSaving}
                className={` pd-option-btn option-btn-save ${
                  isBankDetailSaving ? "disabled" : ""
                }`}
              >
                {isBankDetailSaving ? "Saving..." : "Save"}
              </button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default UserDetailsBankDetails;
