import firebase from "firebase/app";
import "firebase/firestore";
import "firebase/auth";
import "firebase/storage";
import "firebase/functions";
// import { getFunctions, httpsCallable } from 'firebase/functions'

// CLOUDIFY-PROD
// const firebaseConfig = {
//   apiKey: "AIzaSyDB5ROiWgo672ugiYtxwsO2KOPZ8cNU3oA",
//   authDomain: "cloudify-app.firebaseapp.com",
//   projectId: "cloudify-app",
//   storageBucket: "cloudify-app.appspot.com",
//   messagingSenderId: "451547486921",
//   appId: "1:451547486921:web:da769c9e44f48617708b18",
// };

// CLOUDIFY-DEV
// const firebaseConfig = {
//   apiKey: "AIzaSyD4ElFlKc4E9_qfcQP3BZJuKB8cWG10mpg",
//   authDomain: "cloudify-dev-84f4e.firebaseapp.com",
//   projectId: "cloudify-dev-84f4e",
//   storageBucket: "cloudify-dev-84f4e.appspot.com",
//   messagingSenderId: "1029517600597",
//   appId: "1:1029517600597:web:8eae7a96db02af42ed31dc",
// };

// SMART WASH DEV
// const firebaseConfig = {
//   apiKey: "AIzaSyCBHpym5pO3DsTF3WpYWv0p2TbMr6dGaBk",
//   authDomain: "smart-wash-80a57.firebaseapp.com",
//   projectId: "smart-wash-80a57",
//   storageBucket: "smart-wash-80a57.appspot.com",
//   messagingSenderId: "245219829051",
//   appId: "1:245219829051:web:29739c0be84b878a21431e"
// };

// ACCESSFY DEV
// const firebaseConfig = {
//   apiKey: "AIzaSyAtUYeQuj3c0xKPmCKF6_O4O3PsTSGGw-g",
//   authDomain: "accessfy-dev.firebaseapp.com",
//   projectId: "accessfy-dev",
//   storageBucket: "accessfy-dev.firebasestorage.app",
//   messagingSenderId: "215382433328",
//   appId: "1:215382433328:web:74576182bf2293ac80d7f5"
// };

// NASA BIKE TECH
// const firebaseConfig = {
//   apiKey: "AIzaSyAUATR6Nol2HiIYr4GM68x5LgsOrFPHgvA",
//   authDomain: "nasabike-dev.firebaseapp.com",
//   projectId: "nasabike-dev",
//   storageBucket: "nasabike-dev.appspot.com",
//   messagingSenderId: "204219021696",
//   appId: "1:204219021696:web:f434dd476e312bcb422965"
// };

// STAYFY
// const firebaseConfig = {
//   apiKey: "AIzaSyBk-f207Uc4psz1UEbK9ZjdLh4sX-zEFE0",
//   authDomain: "stayfy-176b0.firebaseapp.com",
//   projectId: "stayfy-176b0",
//   storageBucket: "stayfy-176b0.firebasestorage.app",
//   messagingSenderId: "1045877800975",
//   appId: "1:1045877800975:web:854aceedf9f5e57009637a"
// };

// MEGHA LINEN
// const firebaseConfig = {
//   apiKey: "AIzaSyBGv5XqLNCJHl6gpuXI9cfBn3xqBctqIws",
//   authDomain: "meghaworkwear-dev.firebaseapp.com",
//   projectId: "meghaworkwear-dev",
//   storageBucket: "meghaworkwear-dev.appspot.com",
//   messagingSenderId: "33036345314",
//   appId: "1:33036345314:web:f0ffbc3ed202457f62a3bb"
// };

//TUTORTOT
// const firebaseConfig = {
//   apiKey: "AIzaSyB7mPpb_3bENJjncYfBnQbIBi4rHJdYk2M",
//   authDomain: "tutortot-dev.firebaseapp.com",
//   projectId: "tutortot-dev",
//   storageBucket: "tutortot-dev.appspot.com",
//   messagingSenderId: "903630221130",
//   appId: "1:903630221130:web:95998081e4a411d5e7dbb3"
// };

// DNLT DEV
// const firebaseConfig = {
//   apiKey: "AIzaSyC8hYo0z4-MNLIKnmf_WUWcGy7BkEZ6snQ",
//   authDomain: "dnlt-dev.firebaseapp.com",
//   projectId: "dnlt-dev",
//   storageBucket: "dnlt-dev.appspot.com",
//   messagingSenderId: "1029686215129",
//   appId: "1:1029686215129:web:7da8b688bc05119b4459c8"
// };

// VITAL CHH
const firebaseConfig = {
  apiKey: "AIzaSyAlxHP0g5pZlG8OyYzaRmfFBLKcPLOTWFw",
  authDomain: "vitalchh-prod.firebaseapp.com",
  projectId: "vitalchh-prod",
  storageBucket: "vitalchh-prod.appspot.com",
  messagingSenderId: "869053073970",
  appId: "1:869053073970:web:6a85462b7b649301738a2b",
  measurementId: "G-YFZ4YF6PEV",
};

// eStore
// const firebaseConfig = {
//   apiKey: "AIzaSyAcRZXgOSwDtSmpqg258k18jMjMnuPiGVU",
//   authDomain: "estore-e80dc.firebaseapp.com",
//   projectId: "estore-e80dc",
//   storageBucket: "estore-e80dc.appspot.com",
//   messagingSenderId: "557098291275",
//   appId: "1:557098291275:web:3e0774784b0ca697e7bdce",
// };

// YOGAFY
// const firebaseConfig = {
//   apiKey: "AIzaSyAX-EuVHrKNxOpG2Xpu1KZqOUo6I69H6Zw",
//   authDomain: "yogafy-5561c.firebaseapp.com",
//   projectId: "yogafy-5561c",
//   storageBucket: "yogafy-5561c.appspot.com",
//   messagingSenderId: "174919094233",
//   appId: "1:174919094233:web:02e7aec31485a901b413c8",
// };

// HERITAGE HOLIDAY HOMES
// const firebaseConfig = {
//   apiKey: "AIzaSyBSTnJoqdtqnUWm_z5IMY21N3cWR0Oz8lw",
//   authDomain: "heritageholidayhomes-app.firebaseapp.com",
//   projectId: "heritageholidayhomes-app",
//   storageBucket: "heritageholidayhomes-app.appspot.com",
//   messagingSenderId: "251631413017",
//   appId: "1:251631413017:web:639f02d6e5777fb3744788",
//   measurementId: "G-LYE4BSEBM2",
// };

// ECOHOME
// const firebaseConfig = {
//   apiKey: "AIzaSyD7DI2YG1xVAizhB9-BsoiRqOdSVRc37Cc",
//   authDomain: "ecohome-app.firebaseapp.com",
//   projectId: "ecohome-app",
//   storageBucket: "ecohome-app.appspot.com",
//   messagingSenderId: "701260508709",
//   appId: "1:701260508709:web:6b27b6294e80a2d56459cc",
//   measurementId: "G-XT97NYHWP6",
// };

// RESTROFY
// const firebaseConfig = {
//   apiKey: "AIzaSyBSTDd_3l8r41prVH2_-njm6Qhyx0cli-g",
//   authDomain: "restrofy-f9716.firebaseapp.com",
//   projectId: "restrofy-f9716",
//   storageBucket: "restrofy-f9716.appspot.com",
//   messagingSenderId: "568986345671",
//   appId: "1:568986345671:web:0c0dd88cf014b337381ee2",
// };

// CLEANIFY
// const firebaseConfig = {
//   apiKey: "AIzaSyDnh9LXi5Nyooz68ZajnfN8xt0x6rpAkk4",
//   authDomain: "cleanify-60718.firebaseapp.com",
//   projectId: "cleanify-60718",
//   storageBucket: "cleanify-60718.appspot.com",
//   messagingSenderId: "670911794461",
//   appId: "1:670911794461:web:ebacddd13c1f04998325f4",
// };

// JEWELLIFY
// const firebaseConfig = {
//   apiKey: "AIzaSyDW8OaSdafWyFDB2mfg-YFTDcR0d1YrLlg",
//   authDomain: "jewellify-24924.firebaseapp.com",
//   projectId: "jewellify-24924",
//   storageBucket: "jewellify-24924.appspot.com",
//   messagingSenderId: "318339704438",
//   appId: "1:318339704438:web:cc3dc0a3ea438d968f2157",
// };

// GYMIFY
// const firebaseConfig = {
//   apiKey: "AIzaSyB9VdxmRt9xT6T7QKtiS-peqpj4mMhk6mQ",
//   authDomain: "gymify-82dca.firebaseapp.com",
//   projectId: "gymify-82dca",
//   storageBucket: "gymify-82dca.appspot.com",
//   messagingSenderId: "297579172953",
//   appId: "1:297579172953:web:e9138b401f663e91decd48",
// };

// DENTAFY
// const firebaseConfig = {
//   apiKey: "AIzaSyCdqnFg2ykecnUH-MsZs4QUQEVsb01R7Ww",
//   authDomain: "dentafy-faff7.firebaseapp.com",
//   projectId: "dentafy-faff7",
//   storageBucket: "dentafy-faff7.appspot.com",
//   messagingSenderId: "585312963266",
//   appId: "1:585312963266:web:812a0f74592c460a66095b",
// };

// SPORTYFY BADMINTON
// const firebaseConfig = {
//   apiKey: "AIzaSyBTwgPvVqPj-_OwcyZqsDxzP1ChA87XPIE",
//   authDomain: "sportyfy-badminton.firebaseapp.com",
//   projectId: "sportyfy-badminton",
//   storageBucket: "sportyfy-badminton.appspot.com",
//   messagingSenderId: "274330990126",
//   appId: "1:274330990126:web:1f436adae3c2ed20d891bf",
// };

// SPORTYFY CRICKET
// const firebaseConfig = {
//   apiKey: "AIzaSyAA5MYptCjhzv_lzHljIvgbCkbLg6MPgD4",
//   authDomain: "sportyfy-cricket.firebaseapp.com",
//   projectId: "sportyfy-cricket",
//   storageBucket: "sportyfy-cricket.appspot.com",
//   messagingSenderId: "499812369541",
//   appId: "1:499812369541:web:aa9e28a122953490a115ec",
// };

// SPORTYFY FOOTBALL
// const firebaseConfig = {
//   apiKey: "AIzaSyBZWueTzZli0j9mCyEecO7qUr0qR5ZRLLU",
//   authDomain: "sportyfy-football.firebaseapp.com",
//   projectId: "sportyfy-football",
//   storageBucket: "sportyfy-football.appspot.com",
//   messagingSenderId: "1023190044887",
//   appId: "1:1023190044887:web:cdb94b0dbaa9bc2e206092",
// };

// SALONFY
// const firebaseConfig = {
//   apiKey: "AIzaSyDLLTqJujQKu0YimEwBK0MzFaSCvjF63tg",
//   authDomain: "salonfy-36bc6.firebaseapp.com",
//   projectId: "salonfy-36bc6",
//   storageBucket: "salonfy-36bc6.appspot.com",
//   messagingSenderId: "782186657909",
//   appId: "1:782186657909:web:1e9d875435f62549e0a03c",
// };

// AGROFY
// const firebaseConfig = {
//   apiKey: "AIzaSyBBYvKi1qXv0kr3t9GpJoR87UcwYuVdXik",
//   authDomain: "agrofy-bf79d.firebaseapp.com",
//   projectId: "agrofy-bf79d",
//   storageBucket: "agrofy-bf79d.appspot.com",
//   messagingSenderId: "404305896471",
//   appId: "1:404305896471:web:2b1fd64e618bee6b72e852",
// };

// EDUFY
// const firebaseConfig = {
//   apiKey: "AIzaSyC1kYUvwvT2tWvXLken7PUIR4bzOrH8fI0",
//   authDomain: "edufy-16231.firebaseapp.com",
//   projectId: "edufy-16231",
//   storageBucket: "edufy-16231.appspot.com",
//   messagingSenderId: "659218706929",
//   appId: "1:659218706929:web:cdcadfb81fd699103dd450",
// };

// NOFIKR-APP
// const firebaseConfig = {
//   apiKey: "AIzaSyB2lp4Ob-oDsWvjJ3LW1EML2AzHdYw8a28",
//   authDomain: "nofikr-app.firebaseapp.com",
//   projectId: "nofikr-app",
//   storageBucket: "nofikr-app.appspot.com",
//   messagingSenderId: "548545225074",
//   appId: "1:548545225074:web:6703ed764078cb95f17deb",
//   measurementId: "G-2JLX10QNBZ",
// };

// EDUFY KIDS
// const firebaseConfig = {
//   apiKey: "AIzaSyD9eeFELh54880ib5bjMJMPw5tHtH3rPvs",
//   authDomain: "edufy-kids.firebaseapp.com",
//   projectId: "edufy-kids",
//   storageBucket: "edufy-kids.appspot.com",
//   messagingSenderId: "917808670823",
//   appId: "1:917808670823:web:f719f6d97282e341930cc2",
// };

// EDUFY PRIMARY
// const firebaseConfig = {
//   apiKey: "AIzaSyBTXXjhdCkdPthetQVmYHYuQym5qRzooFg",
//   authDomain: "edufy-primary.firebaseapp.com",
//   projectId: "edufy-primary",
//   storageBucket: "edufy-primary.appspot.com",
//   messagingSenderId: "1091526641712",
//   appId: "1:1091526641712:web:7dfb0bba754a30a709c5f5",
// };

// EDUFY COLLEGE
// const firebaseConfig = {
//   apiKey: "AIzaSyAHA8T9pJPUqw-0zLvyj9ML0I1af8oDqYQ",
//   authDomain: "edufy-college.firebaseapp.com",
//   projectId: "edufy-college",
//   storageBucket: "edufy-college.appspot.com",
//   messagingSenderId: "732615220053",
//   appId: "1:732615220053:web:6bbce6b02e9213674daddc",
// };

// EDUFY ACADEMY
// const firebaseConfig = {
//   apiKey: "AIzaSyCPQd3dtY6M7hjNgDSNoOS6TABi0xU50lY",
//   authDomnain: "edufy-academy.firebaseapp.com",
//   projectId: "edufy-academy",
//   storageBucket: "edufy-academy.appspot.com",
//   messagingSenderId: "722571501993",
//   appId: "1:722571501993:web:3b4f665223bd83328224b8",
// };

// W18 SPORTS UNIVERSE
// const firebaseConfig = {
//   apiKey: "AIzaSyBkfsL2Cf13JEMoWF6Sgzam-rG0vtsSkIg",
//   authDomain: "w18sportsuniverse.firebaseapp.com",
//   projectId: "w18sportsuniverse",
//   storageBucket: "w18sportsuniverse.appspot.com",
//   messagingSenderId: "425834835959",
//   appId: "1:425834835959:web:64bdd2c264113de943c8f2",
//   measurementId: "G-PK945WLJDZ",
// };

// smart wash production
// const firebaseConfig = {
//   apiKey: "AIzaSyD197gDa5-zZngCW5rPCTU4V7YTt-MqQwU",
//   authDomain: "smart-wash-prod.firebaseapp.com",
//   projectId: "smart-wash-prod",
//   storageBucket: "smart-wash-prod.appspot.com",
//   messagingSenderId: "46360843329",
//   appId: "1:46360843329:web:1b902e85fd15ff88a4a2c0",
//   measurementId: "G-0T2TYWWJZ2"
// };

// Happey ERP DEV
// const firebaseConfig = {
//   apiKey: "AIzaSyC_0FooU6BbLQhgqueEGLcqMVRxzUS5pYQ",
//   authDomain: "happyerp-dev.firebaseapp.com",
//   projectId: "happyerp-dev",
//   storageBucket: "happyerp-dev.firebasestorage.app",
//   messagingSenderId: "118358626978",
//   appId: "1:118358626978:web:4b9c889ba4022f4ccebdfb",
// };

// PEACE THREAD
// const firebaseConfig = {
//   apiKey: "AIzaSyDUxSbrzx47tOsdZh-iFSq8rn7v353Sfmg",
//   authDomain: "peace-thread-dev.firebaseapp.com",
//   projectId: "peace-thread-dev",
//   storageBucket: "peace-thread-dev.firebasestorage.app",
//   messagingSenderId: "1031104604124",
//   appId: "1:1031104604124:web:0254bbe555fe0d1a8a9119"
// };

// initialize firebase
const app = firebase.initializeApp(firebaseConfig);

// initialize Services
const projectFirestore = firebase.firestore();
const projectAuth = firebase.auth();
const projectAuthObj = firebase.auth;
const projectStorage = firebase.storage();
const projectFunctions = firebase.functions();

// timestamp
const timestamp = firebase.firestore.Timestamp;

export {
  app,
  projectFirestore,
  projectAuth,
  projectAuthObj,
  projectStorage,
  projectFunctions,
  timestamp,
};
