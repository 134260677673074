import React, { useEffect, useRef, useState } from "react";
import { Modal } from "react-bootstrap";
import {
  projectFirestore,
  projectStorage,
} from "../../../firebase config/config";
import "./UserDetailsUploadDoc.scss";

const MAX_FILE_SIZE = 4 * 1024 * 1024;

const UserDetailsUploadDoc = ({
  selectedDocType,
  userProfileDoc,
  userProfileId,
  updateDocument,
  folderName,
  title,
  className = "",
}) => {
  const fileInputRefs = useRef({});
  const [isDocUploading, setIsDocUploading] = useState({});
  const [files, setFiles] = useState({});
  const [uploadDocInProgress, setUploadDocInProgress] = useState({});
  const [uploadModalOpen, setUploadModalOpen] = useState(false);
  const [previewModalOpen, setPreviewModalOpen] = useState(false);
  const [completionStatus, setCompletionStatus] = useState({});
  const [isDragging, setIsDragging] = useState(false);

  const getDocKeys = (docType) => {
    const baseKey = docType.replace(/ /g, "").toLowerCase();
    return {
      urlKey: `${baseKey}Url`,
      typeKey: `${baseKey}Type`,
      updatedAtKey: `${baseKey}UpdatedAt`,
    };
  };

  useEffect(() => {
    if (!userProfileId) return;

    const docRef = projectFirestore.collection("users").doc(userProfileId);

    const unsubscribe = docRef.onSnapshot((docSnap) => {
      if (docSnap.exists) {
        const userData = docSnap.data();
        setCompletionStatus((prev) => ({
          ...prev,
          [selectedDocType]: userData[getDocKeys(selectedDocType).urlKey]
            ? { status: "success", statusMsg: "Uploaded" }
            : { status: "pending", statusMsg: "Not Uploaded" },
        }));
      }
    });

    return () => unsubscribe(); // Cleanup listener
  }, [userProfileId, selectedDocType]);

  const allowedTypes = [
    "image/png",
    "image/jpeg",
    "image/jpg",
    "application/pdf",
  ];

  const handleDocFileChange = (event) => {
    const file = event.target.files[0];
    if (!file) return;
    // Check file size
    if (file.size > MAX_FILE_SIZE) {
      alert("File size exceeds the 10MB limit. Please upload a smaller file.");
      return;
    }
    if (!allowedTypes.includes(file.type)) {
      alert("Invalid file type. Only PNG, JPG, JPEG, and PDF are allowed.");
      return;
    }
    console.log(file);
    setFiles((prev) => {
      const updatedFiles = { ...prev, [selectedDocType]: file };
      console.log("Updated files state:", updatedFiles); // Log the updated state
      return updatedFiles;
    });

    uploadDocument(selectedDocType, file);
  };
  const getFileType = (file) => {
    if (!file) {
      console.error("File is undefined");
      return;
    }
    const fileExtension = file.name.split(".").pop().toLowerCase();
    return fileExtension === "pdf" ? "pdf" : "image";
  };

  // upload document code with delete previous doc from storage automatically
  const uploadDocument = async (docType, file) => {
    try {
      setIsDocUploading((prev) => ({ ...prev, [docType]: true }));
      if (!file) {
        console.error("File is undefined", file);
        return;
      }
      console.log(file);
      const fileType = getFileType(file);
      // Check if there's an existing document and delete it
      const { urlKey, typeKey, updatedAtKey } = getDocKeys(docType);
      if (userProfileDoc && userProfileDoc[urlKey]) {
        const oldFileURL = userProfileDoc[urlKey];
        const oldFileRef = projectStorage.refFromURL(oldFileURL);

        try {
          await oldFileRef.delete();
          console.log(`Previous file for ${docType} deleted successfully.`);
        } catch (error) {
          console.error(`Error deleting previous file for ${docType}:`, error);
        }
      }
      setUploadDocInProgress((prev) => ({ ...prev, [docType]: 0 }));
      // Upload the new document
      const storageRef = projectStorage.ref(
        `${folderName}/${userProfileId}/${docType}/${file.name}`
      );
      const uploadTask = storageRef.put(file);
      uploadTask.on(
        "state_changed",
        (snapshot) => {
          const progress =
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          setUploadDocInProgress((prev) => ({ ...prev, [docType]: progress }));
        },
        (error) => {
          console.error(`Error uploading ${docType}:`, error);
          setCompletionStatus((prev) => ({
            ...prev,
            [docType]: { status: "error", statusMsg: "Upload Failed" },
          }));
          setIsDocUploading((prev) => ({ ...prev, [docType]: false }));
          setUploadDocInProgress((prev) => ({ ...prev, [docType]: 0 }));
        },
        async () => {
          const fileURL = await storageRef.getDownloadURL();
          await updateDocument(userProfileId, {
            [urlKey]: fileURL,
            [typeKey]: fileType,
            [updatedAtKey]: new Date(Date.now()).toISOString(),
          });
          setFiles((prev) => {
            const updatedFiles = { ...prev };
            delete updatedFiles[docType];
            return updatedFiles;
          });
          setCompletionStatus((prev) => ({
            ...prev,
            [docType]: {
              status: "success",
              statusMsg: "Uploaded",
            },
          }));
          setIsDocUploading((prev) => ({ ...prev, [docType]: false }));
        }
      );
    } catch (error) {
      console.error(`Error uploading ${docType}:`, error);
      setCompletionStatus((prev) => ({
        ...prev,
        [docType]: {
          status: "error",
          statusMsg: "Failed",
        },
      }));
      setIsDocUploading((prev) => ({ ...prev, [docType]: false }));
    }
  };

  // code for delete uploaded document from database and storage both
  const handleDeleteDocument = async (docType) => {
    try {
      const { urlKey, typeKey, updatedAtKey } = getDocKeys(docType);
      if (userProfileDoc && userProfileDoc[urlKey]) {
        const oldFileURL = userProfileDoc[urlKey];
        const oldFileRef = projectStorage.refFromURL(oldFileURL);

        // Delete the file from storage
        await oldFileRef.delete();
        console.log(`File for ${docType} deleted successfully.`);

        // Remove the URL and file type from the database
        await updateDocument(userProfileId, {
          [urlKey]: null,
          [typeKey]: null,
          [updatedAtKey]: null,
        });

        console.log(`Database entry for ${docType} removed successfully.`);
      }
    } catch (error) {
      console.error(`Error deleting file for ${docType}:`, error);
    }
  };

  // code for delete upload document with confirmation popup
  const [showConfirmModalForDeleteDoc, setShowConfirmModalForDeleteDoc] =
    useState(false);
  // const [modalDocType, setModalDocType] = useState("");
  const [isUploadedDocDeleting, setIsUploadedDocDeleting] = useState(false);

  const handleDeleteConfirm = async () => {
    try {
      setIsUploadedDocDeleting(true);
      await handleDeleteDocument(selectedDocType); // Call the delete function
      setIsUploadedDocDeleting(false);
      setShowConfirmModalForDeleteDoc(false); // Close modal after successful deletion
    } catch (error) {
      console.error(`Error deleting ${selectedDocType}:`, error);
      setIsUploadedDocDeleting(false);
    }
  };

  const handleDeleteClick = (docType) => {
    setShowConfirmModalForDeleteDoc(true);
  };
  // code for redering file with delete button
  const renderFilePreview = (docType) => {
    const { urlKey, typeKey } = getDocKeys(docType);
    return (
      <div className="image_container_inner">
        {userProfileDoc?.[typeKey] === "image" ? (
          <img src={userProfileDoc?.[urlKey]} alt={`${docType} preview`} />
        ) : (
          <iframe
            title="PDF Viewer"
            src={userProfileDoc?.[urlKey]}
            className="document-preview"
            style={{
              width: "100%",
              aspectRatio: "3/2",
            }}
          />
        )}
      </div>
    );
  };
  //code ended for employee documents

  return (
    <div className={`pd-document-card ${className}`}>
      <div className="document-file">
        <div className="document-file-data">
          <div className="document-file-icon">
            <span class="material-symbols-outlined">description</span>
          </div>
          <div className="document-file-name">
            <h3>{title}</h3>
            <span>
              {!userProfileDoc?.[getDocKeys(selectedDocType).updatedAtKey]
                ? "Not Provided Yet"
                : `Updated: ${
                    userProfileDoc[
                      getDocKeys(selectedDocType).updatedAtKey
                    ].split("T")[0]
                  }`}
            </span>
          </div>
        </div>
        <div
          className={`uploaded-status ${completionStatus?.[selectedDocType]?.status}-uploaded`}
        >
          {completionStatus?.[selectedDocType]?.statusMsg}
        </div>
      </div>
      <div className="document-option">
        {completionStatus?.[selectedDocType]?.status === "success" ? (
          <>
            <div
              className="document-preview"
              onClick={() => {
                setPreviewModalOpen(true);
                // setSelectedDocType(docType);
              }}
            >
              <span class="material-symbols-outlined">visibility</span>
            </div>
            <div
              className="document-delete"
              onClick={() => handleDeleteClick(selectedDocType)}
            >
              <span class="material-symbols-outlined">delete</span>
            </div>
          </>
        ) : (
          <button
            className="document-upload"
            onClick={() => {
              setUploadModalOpen(true);
            }}
            disabled={isDocUploading[selectedDocType]}
          >
            {isDocUploading[selectedDocType] ? "Uploading..." : "Upload"}
          </button>
        )}
      </div>
      {/* Uploading Modal */}
      <Modal
        show={uploadModalOpen}
        onHide={() => setUploadModalOpen(false)}
        centered
        size="lg"
        className="upload-modal"
      >
        <Modal.Header closeButton className="upload-modal-header">
          <div>
            <h3>Upload {selectedDocType}</h3>
            <p>Drop your images or pdf file here or click to browse</p>
          </div>
        </Modal.Header>
        <Modal.Body>
          {isDocUploading[selectedDocType] ||
          completionStatus[selectedDocType]?.status !== "pending" ? (
            <div className="upload-progress">
              <p>
                {completionStatus[selectedDocType]?.status === "success" ? (
                  <span class="material-symbols-outlined">check_circle</span>
                ) : completionStatus[selectedDocType]?.status === "error" ? (
                  <span class="material-symbols-outlined">warning</span>
                ) : null}
                {completionStatus[selectedDocType]?.status === "success"
                  ? "Uploaded"
                  : "Uploading"}
                {Math.round(uploadDocInProgress[selectedDocType])}%
              </p>
              <div className="progress-bar">
                <div
                  className="progress-fill"
                  style={{
                    width: `${Math.round(
                      uploadDocInProgress[selectedDocType]
                    )}%`,
                  }}
                />
              </div>
            </div>
          ) : (
            <div
              onClick={() => fileInputRefs.current[selectedDocType]?.click()}
              className="upload-modal-body"
              onDragOver={(e) => {
                e.preventDefault();
                setIsDragging(true);
              }}
              onDragLeave={(e) => {
                e.preventDefault();
                setIsDragging(false);
              }}
              onDrop={(e) => {
                e.preventDefault();
                setIsDragging(false);
                const file = e.dataTransfer.files[0];
                if (file) {
                  setFiles((prev) => ({ ...prev, [selectedDocType]: file }));
                  uploadDocument(selectedDocType, file);
                }
              }}
            >
              <div className="upload-icon">
                <span class="material-symbols-outlined">upload</span>
              </div>
              <div className="upload-info">
                <p className="upload-text-1">
                  <span className="text-primary">Click to upload </span>
                  or drag and drop
                </p>
                <p className="upload-text-2">PNG, JPG, JPEG, PDF upto 10MB</p>
              </div>
              <input
                type="file"
                name=""
                id=""
                ref={(el) => (fileInputRefs.current[selectedDocType] = el)}
                onChange={(e) => handleDocFileChange(e)}
                style={{ display: "none" }}
              />
            </div>
          )}
        </Modal.Body>
        {completionStatus[selectedDocType]?.status !== "pending" && (
          <Modal.Footer style={{ border: "none" }}>
            <button
              onClick={() => setUploadModalOpen(false)}
              className="upload-close-btn"
            >
              Close
            </button>
          </Modal.Footer>
        )}
      </Modal>
      {/* Preview Modal */}
      <Modal
        show={previewModalOpen}
        onHide={() => setPreviewModalOpen(false)}
        centered
        size="lg"
      >
        <Modal.Header
          closeButton
          // style={{ backgroundColor: "#000" }}
        >
          <Modal.Title>{selectedDocType}</Modal.Title>
        </Modal.Header>
        <Modal.Body>{renderFilePreview(selectedDocType)}</Modal.Body>
      </Modal>
      {/* Delete Modal */}
      <Modal
        show={showConfirmModalForDeleteDoc}
        onHide={() => setShowConfirmModalForDeleteDoc(false)}
        centered
      >
        <Modal.Header
          className="justify-content-center"
          style={{
            paddingBottom: "0px",
            border: "none",
          }}
        >
          <h5>Confirm Deletion</h5>
        </Modal.Header>
        <Modal.Body
          className="text-center"
          style={{
            color: "#FA6262",
            fontSize: "20px",
            border: "none",
          }}
        >
          Are you sure you want to delete the uploaded {selectedDocType}?
        </Modal.Body>
        <Modal.Footer
          className="d-flex justify-content-between"
          style={{
            border: "none",
            gap: "15px",
          }}
        >
          <div
            className="cancel_btn"
            onClick={handleDeleteConfirm}
            disabled={isUploadedDocDeleting}
          >
            {isUploadedDocDeleting ? "Deleting..." : "Yes"}
          </div>
          <div
            className="done_btn"
            onClick={() => setShowConfirmModalForDeleteDoc(false)}
          >
            No
          </div>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default UserDetailsUploadDoc;
