import React from "react";
import { Link } from "react-router-dom";
import { useDocument } from "../../../firebase files/hooks/useDocument";

//importing scss
import "./UserDetailsLeft.scss";

const UserDetailsLeft = ({ userProfileId }) => {
  // get and update code start
  const { document: userProfileDoc, error: userProfileError } = useDocument(
    "users",
    userProfileId
  );
  return (
    <div className="profile-card animate-slide-in">
      <div className="profile-photo">
        <img
          src={userProfileDoc?.photoURL ?? "/assets/img/profile-option-1.png"}
          alt="profilt-photo"
        />

        <div style={{ backgroundColor: "#fff" }} className="online-indicator">
          <div
            className={`status-indicator ${
              userProfileDoc?.online ? "online" : "offline"
            }`}
          />
        </div>
      </div>
      <div className="profile-info">
        <h2>{userProfileDoc?.fullName ?? "John Doe"}</h2>
        <div className="contact-info">
          <span>
            {userProfileDoc?.phoneNumber.replace(
              /(\d{2})(\d{5})(\d{5})/,
              "+$1 $2-$3"
            ) ?? "+91 98765-43210"}
          </span>
        </div>
        <div className="contact-info">
          <span>{userProfileDoc?.email ?? "JohnDoe@gmail.com"}</span>
        </div>
        <div className="contact-info">
          <span>
            {userProfileDoc?.city ?? " "}, {userProfileDoc?.country ?? " "}{" "}
          </span>
        </div>
        <div className="contact-methods">
          <Link
            to={`https://wa.me/+${
              userProfileDoc && userProfileDoc.phoneNumber
            }`}
            className="icon right"
          >
            <img src="/assets/img/whatsappbig.png" alt="" />
          </Link>
          <Link
            to={`mailto:${userProfileDoc && userProfileDoc.email}`}
            className="icon left"
          >
            <img src="/assets/img/gmailbig.png" alt="" />
          </Link>
        </div>
      </div>
    </div>
  );
};

export default UserDetailsLeft;
