import React, { useState, useEffect } from "react";
import { useFirestore } from "../../../firebase files/hooks/useFirestore";
import { useDocument } from "../../../firebase files/hooks/useDocument";
import { format } from "date-fns";
import Select from "react-select";

const UserDetailsEmpDetails = ({ userProfileId }) => {
  const { document: userProfileDoc, error: userProfileError } = useDocument(
    "users",
    userProfileId
  );
  const { document: dbDesignationDocuments } = useDocument(
    "m_designation",
    "designationDoc"
  );
  const { document: dbDepartmentDocuments } = useDocument(
    "m_department",
    "departmentDoc"
  );
  const { updateDocument } = useFirestore("users");

  const [isEditing, setIsEditing] = useState(false);
  const [employeeId, setEmployeeId] = useState("");
  const [department, setDepartment] = useState("");
  const [designation, setDesignation] = useState("");
  const [grade, setGrade] = useState("");
  const [uanNumber, setUanNumber] = useState("");
  const [panNumber, setPanNumber] = useState("");
  const [aadhaarNumber, setAadhaarNumber] = useState("");
  const [employeeDetailUpdateMessage, setEmployeeDetailUpdateMessage] =
    useState("");
  const [isEdUpdating, setIsEdUpdating] = useState(false);
  const [dateOfJoining, setDateOfJoining] = useState("");
  const [dateOfLeaving, setDateOfLeaving] = useState("");
  const [managerName, setManagerName] = useState("");
  const [edMessageType, setEdMessageType] = useState("");

  const handleCancelClick = () => {
    setIsEditing(!isEditing);
    if (userProfileDoc) {
      setEmployeeId(userProfileDoc.employeeId || "");
      setDepartment(userProfileDoc.department || "");
      setDesignation(userProfileDoc.designation || "");
      setGrade(userProfileDoc.grade || "");
      setUanNumber(userProfileDoc.uanNumber || "");
      setPanNumber(userProfileDoc.panNumber || "");
      setAadhaarNumber(userProfileDoc.aadhaarNumber || "");
      setDateOfJoining(userProfileDoc.dateOfJoining || "");
      setDateOfLeaving(
        userProfileDoc.dateOfJoining ? userProfileDoc.dateOfLeaving || "" : ""
      );
      setManagerName(userProfileDoc.managerName || "");
    }
  };

  useEffect(() => {
    if (userProfileDoc) {
      setEmployeeId(userProfileDoc.employeeId || "");
      setDepartment(userProfileDoc.department || "");
      setDesignation(userProfileDoc.designation || "");
      setGrade(userProfileDoc.grade || "");
      setUanNumber(userProfileDoc.uanNumber || "");
      setPanNumber(userProfileDoc.panNumber || "");
      setAadhaarNumber(userProfileDoc.aadhaarNumber || "");
      setDateOfJoining(userProfileDoc.dateOfJoining || "");
      setDateOfLeaving(userProfileDoc.dateOfLeaving || "");
      setManagerName(userProfileDoc.managerName || "");
    } else {
      // Set default values for new entries
      setEmployeeId("");
      setDepartment("");
      setDesignation("");
      setGrade("");
      setUanNumber("");
      setPanNumber("");
      setAadhaarNumber("");
      setDateOfJoining("");
      setDateOfLeaving("");
      setManagerName("");
    }
  }, [userProfileDoc]);

  // Department and Designation Dropdowns
  // const departmentOptions = [
  //   { value: "it", label: "IT" },
  //   { value: "operation", label: "Operation" },
  //   { value: "marketing", label: "Marketing" },
  //   { value: "hr", label: "HR" },
  // ];

  // const designationOptions = [
  //   { value: "manager", label: "Manager" },
  //   { value: "tester", label: "Tester" },
  //   { value: "developer", label: "Developer" },
  //   { value: "hr", label: "HR" },
  //   // Add more options here
  // ];

  const handleUpdateEmployeeDetails = async () => {
    // Validation for required fields
    if (
      !employeeId ||
      !dateOfJoining ||
      !panNumber ||
      !aadhaarNumber ||
      !department ||
      !designation ||
      !grade ||
      department.length === 0
    ) {
      setEmployeeDetailUpdateMessage("Please fill all required fields.");
      setEdMessageType("error_msg");
      setTimeout(() => {
        setEmployeeDetailUpdateMessage("");
        setEdMessageType("");
      }, 4000); // Clear message after 5 seconds

      return;
    }

    // Validation for UAN number (exactly 12 digits)
    if (uanNumber && !/^\d{12}$/.test(uanNumber)) {
      setEmployeeDetailUpdateMessage("UAN number must be exactly 12 digits.");
      setEdMessageType("error_msg");
      setTimeout(() => {
        setEmployeeDetailUpdateMessage("");
        setEdMessageType("");
      }, 4000);
      return;
    }

    // Validation for PAN card (minimum 10 characters)
    if (panNumber.length < 10) {
      setEmployeeDetailUpdateMessage(
        "PAN card must have at least 10 characters."
      );
      setEdMessageType("error_msg");
      setTimeout(() => {
        setEmployeeDetailUpdateMessage("");
        setEdMessageType("");
      }, 4000);
      return;
    }

    // Remove spaces from the formatted Aadhaar number for validation
    const rawAadhaarNumber = aadhaarNumber.replace(/\s/g, "");

    // Validation for Aadhaar number (exactly 12 digits)
    if (!/^\d{12}$/.test(rawAadhaarNumber)) {
      setEmployeeDetailUpdateMessage(
        "Aadhaar number must be exactly 12 digits."
      );
      setEdMessageType("error_msg");
      setTimeout(() => {
        setEmployeeDetailUpdateMessage("");
        setEdMessageType("");
      }, 4000);
      return;
    }

    // Validation for date of joining and leaving
    if (
      !dateOfJoining ||
      (dateOfLeaving && new Date(dateOfLeaving) <= new Date(dateOfJoining))
    ) {
      setEmployeeDetailUpdateMessage(
        "Please ensure the Date of Joining is set and Date of Leaving is after the Date of Joining."
      );
      setEdMessageType("error_msg");
      setTimeout(() => {
        setEmployeeDetailUpdateMessage("");
        setEdMessageType("");
      }, 4000);
      return;
    }

    const dataSet = {
      employeeId,
      department,
      designation,
      uanNumber,
      panNumber,
      aadhaarNumber,
      dateOfJoining,
      dateOfLeaving,
      managerName,
      grade,
    };

    setIsEdUpdating(true);
    setEmployeeDetailUpdateMessage("");

    try {
      await updateDocument(userProfileId, dataSet);
      setEdMessageType("success_msg");
      setEmployeeDetailUpdateMessage("Employee details updated successfully!");
      setTimeout(() => {
        setIsEditing(!isEditing);
      }, 4000);
    } catch (error) {
      console.error("Error updating employee details:", error);
      setEdMessageType("error_msg");
      setEmployeeDetailUpdateMessage(
        "Failed to update employee details. Please try again."
      );
    } finally {
      setIsEdUpdating(false);
      setTimeout(() => {
        setEmployeeDetailUpdateMessage("");
        setEdMessageType("");
      }, 4000); // Clear message after 5 seconds
    }
  };

  return (
    <div className="pd-cards animate-slide-in">
      <div className="pd-heading">
        <h2>Employee Details</h2>
        {isEditing ? null : (
          <div
            className="pd-edit-state"
            onClick={() => {
              setIsEditing((prev) => !prev);
            }}
          >
            <span className="material-symbols-outlined">edit</span>
            Edit
          </div>
        )}
      </div>
      <div className="pd-data">
        {!isEditing && (
          <>
            <div className="pd-data-element">
              <div className="pd-icon">
                <img src="/assets/img/VisitingDays.png" alt="" />
              </div>
              <div className="pd-data-value">
                <h6>Date of joining</h6>
                <p>
                  {userProfileDoc && userProfileDoc.dateOfJoining
                    ? format(
                        new Date(userProfileDoc.dateOfJoining), // Ensure it's converted to a Date object
                        "dd-MMM-yyyy"
                      )
                    : "Not provided yet"}
                </p>
              </div>
            </div>
            <div className="pd-data-element">
              <div className="pd-icon">
                <img src="/assets/img/VisitingDays.png" alt="" />
              </div>
              <div className="pd-data-value">
                <h6>Date of Leaving</h6>
                <p>
                  {" "}
                  {userProfileDoc && userProfileDoc.dateOfLeaving
                    ? format(
                        new Date(userProfileDoc.dateOfLeaving), // Ensure it's converted to a Date object
                        "dd-MMM-yyyy"
                      )
                    : "Not provided yet"}
                </p>
              </div>
            </div>
            <div className="pd-data-element">
              <div className="pd-icon">
                <img src="/assets/img/id.png" alt="" />
              </div>
              <div className="pd-data-value">
                <h6>Employee ID</h6>
                <p>
                  {" "}
                  {userProfileDoc && userProfileDoc.employeeId
                    ? userProfileDoc.employeeId
                    : "Not provided yet"}
                </p>
              </div>
            </div>
            <div className="pd-data-element">
              <div className="pd-icon">
                <img src="/assets/img/department.png" alt="" />
              </div>
              <div className="pd-data-value">
                <h6>Department</h6>
                <p>
                  {" "}
                  {userProfileDoc && userProfileDoc.department
                    ? userProfileDoc.department.value
                    : "Not provided yet"}
                </p>
              </div>
            </div>
            <div className="pd-data-element">
              <div className="pd-icon">
                <img src="/assets/img/designation.png" alt="" />
              </div>
              <div className="pd-data-value">
                <h6>Designation</h6>
                <p>
                  {userProfileDoc && userProfileDoc.designation
                    ? userProfileDoc.designation.value
                    : "Not provided yet"}
                </p>
              </div>
            </div>
            <div className="pd-data-element">
              <div className="pd-icon">
                <img src="/assets/img/grade.png" alt="" />
              </div>
              <div className="pd-data-value">
                <h6>Grade</h6>
                <p>
                  {userProfileDoc && userProfileDoc.grade
                    ? userProfileDoc.grade
                    : "Not provided yet"}
                </p>
              </div>
            </div>
            <div className="pd-data-element">
              <div className="pd-icon">
                <img src="/assets/img/grade.png" alt="" />
              </div>
              <div className="pd-data-value">
                <h6>Manager name</h6>
                <p>
                  {userProfileDoc && userProfileDoc.managerName
                    ? userProfileDoc.managerName
                    : "Not provided yet"}
                </p>
              </div>
            </div>
            <div className="pd-data-element">
              <div className="pd-icon">
                <img src="/assets/img/id-card.png" alt="" />
              </div>
              <div className="pd-data-value">
                <h6>UAN Number</h6>
                <p>
                  {userProfileDoc && userProfileDoc.uanNumber
                    ? userProfileDoc.uanNumber
                    : "Not provided yet"}
                </p>
              </div>
            </div>
            <div className="pd-data-element">
              <div className="pd-icon">
                <img src="/assets/img/id-card.png" alt="" />
              </div>
              <div className="pd-data-value">
                <h6>PAN Number</h6>
                <p>
                  {" "}
                  {userProfileDoc && userProfileDoc.panNumber
                    ? userProfileDoc.panNumber
                    : "Not provided yet"}
                </p>
              </div>
            </div>
            <div className="pd-data-element">
              <div className="pd-icon">
                <img src="/assets/img/id-card.png" alt="" />
              </div>
              <div className="pd-data-value">
                <h6>Aadhar Number</h6>
                <p>
                  {" "}
                  {userProfileDoc && userProfileDoc.aadhaarNumber
                    ? userProfileDoc.aadhaarNumber
                    : "Not provided yet"}
                </p>
              </div>
            </div>
          </>
        )}
        {isEditing && (
          <div className="col">
            <div className="row row_gap">
              <div className="col-lg-4 col-md-6 col-sm-12">
                <div className="form-field">
                  <label className="input-label required-field">
                    Date of Joining
                  </label>
                  <input
                    type="date"
                    value={dateOfJoining}
                    onChange={(e) => setDateOfJoining(e.target.value)}
                    placeholder="Select Date of Joining"
                    className="form-input"
                  />
                </div>
              </div>
              <div className="col-lg-4 col-md-6 col-sm-12">
                <div className="form-field">
                  <label className="input-label required-field">
                    Employee ID
                  </label>
                  <input
                    type="number"
                    value={employeeId}
                    // onChange={(e) => setEmployeeId(e.target.value)}
                    onChange={(e) => {
                      const newValue = e.target.value;
                      if (newValue.length <= 4) {
                        setEmployeeId(newValue);
                      }
                    }}
                    maxLength="4"
                    placeholder="Enter Employee ID"
                    className="form-input"
                  />
                </div>
              </div>
              <div className="col-lg-4 col-md-6 col-sm-12">
                <div className="form-field">
                  <label className="input-label required-field">
                    Department
                  </label>
                  <Select
                    // isMulti
                    value={department}
                    onChange={setDepartment}
                    options={dbDepartmentDocuments?.departments}
                    placeholder="Select Department"
                    className="react-select-container"
                    classNamePrefix="react-select"
                  />
                </div>
              </div>
              <div className="col-lg-4 col-md-6 col-sm-12">
                <div className="form-field">
                  <label className="input-label required-field">
                    Designation
                  </label>
                  <Select
                    value={designation}
                    onChange={setDesignation}
                    options={dbDesignationDocuments?.designation}
                    placeholder="Select Designation"
                    className="react-select-container"
                    classNamePrefix="react-select"
                  />
                </div>
              </div>
              <div className="col-lg-4 col-md-6 col-sm-12">
                <div className="form-field">
                  <label className="input-label required-field">Grade</label>
                  <input
                    type="text"
                    value={grade}
                    onChange={(e) => setGrade(e.target.value)}
                    maxLength="4"
                    placeholder="Enter Employee ID"
                    className="form-input"
                  />
                </div>
              </div>
              <div className="col-lg-4 col-md-6 col-sm-12">
                <div className="form-field">
                  <label className="input-label required-field">
                    Manager Name
                  </label>
                  <input
                    type="text"
                    className="form-input"
                    value={managerName}
                    onChange={(e) => setManagerName(e.target.value)}
                    placeholder="Enter Manager Name"
                    onKeyPress={(e) => {
                      const regex = /^[a-zA-Z\s]*$/; // Only letters and spaces allowed
                      if (!regex.test(e.key)) {
                        e.preventDefault(); // Prevent invalid input
                      }
                    }}
                  />
                </div>
              </div>
              <div className="col-lg-4 col-md-6 col-sm-12">
                <div className="form-field">
                  <label className="input-label required-field">
                    UAN Number
                  </label>
                  <input
                    type="text"
                    value={uanNumber}
                    className="form-input"
                    // onChange={(e) => setUanNumber(e.target.value)}
                    onChange={(e) => {
                      const newValue = e.target.value;
                      if (newValue.length <= 12) {
                        setUanNumber(newValue);
                      }
                    }}
                    placeholder="Enter UAN Number"
                    maxLength="12"
                    style={{
                      textTransform: "uppercase",
                    }}
                  />
                </div>
              </div>
              <div className="col-lg-4 col-md-6 col-sm-12">
                <div className="form-field">
                  <label className="input-label required-field">
                    PAN Number
                  </label>

                  <input
                    type="text"
                    className="form-input"
                    value={panNumber}
                    onChange={(e) => {
                      const newValue = e.target.value.replace(/\s+/g, ""); // Remove spaces
                      if (newValue.length <= 10) {
                        setPanNumber(newValue);
                      }
                    }}
                    style={{
                      textTransform: "uppercase",
                    }}
                    placeholder="Enter PAN Number"
                    maxLength="10"
                  />
                </div>
              </div>
              <div className="col-lg-4 col-md-6 col-sm-12">
                <div className="form-field">
                  <label className="input-label required-field">
                    Aadhaar Number
                  </label>
                  <input
                    type="text"
                    className="form-input"
                    value={aadhaarNumber}
                    onChange={(e) => {
                      const input = e.target.value.replace(/\s/g, ""); // Remove any existing spaces
                      if (/^\d*$/.test(input) && input.length <= 12) {
                        // Allow only digits and max 12 characters
                        const formatted =
                          input
                            .match(/.{1,4}/g) // Group digits in chunks of 4
                            ?.join(" ") || ""; // Join chunks with a space
                        setAadhaarNumber(formatted);
                      }
                    }}
                    placeholder="Enter Aadhaar Number"
                  />
                </div>
              </div>
              {userProfileDoc && userProfileDoc.dateOfJoining && (
                <div className="col-lg-4 col-md-6 col-sm-12">
                  <div className="form-field">
                    <label className="input-label required-field">
                      Date of Leaving
                    </label>
                    <input
                      type="date"
                      value={dateOfLeaving}
                      onChange={(e) => setDateOfLeaving(e.target.value)}
                      min={dateOfJoining} // Ensure only dates after Date of Joining can be selected
                      placeholder="Select Date of Leaving"
                      className="form-input"
                    />
                  </div>
                </div>
              )}
            </div>
            <div className="pd-edit-option">
              {employeeDetailUpdateMessage && (
                <p className={`edit-msg_area ${edMessageType}`}>
                  {employeeDetailUpdateMessage}
                </p>
              )}

              <button
                onClick={handleCancelClick}
                disabled={isEdUpdating}
                className={` pd-option-btn option-btn-cancel ${
                  isEdUpdating ? "disabled" : ""
                }`}
              >
                Cancel
              </button>
              <button
                onClick={handleUpdateEmployeeDetails}
                disabled={isEdUpdating}
                className={` pd-option-btn option-btn-save ${
                  isEdUpdating ? "disabled" : ""
                }`}
              >
                {isEdUpdating ? "Saving..." : "Save"}
              </button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default UserDetailsEmpDetails;
