import { useState, useEffect } from "react";
import "react-phone-input-2/lib/style.css";
import { Alert } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { projectFirestore, timestamp } from "../../firebase config/config";
import { useFirestore } from "../hooks/useFirestore";
import { BeatLoader } from "react-spinners";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import { projectAuth } from "../../firebase config/config";
import { useDocument } from "../hooks/useDocument";

function camelCase(str) {
  return (
    str
      .replace(/\s(.)/g, function (a) {
        return a.toUpperCase();
      })
      // .replace(/\s/g, '')
      .replace(/^(.)/, function (b) {
        return b.toUpperCase();
      })
  );
}

const EmailPasswordLoginSignup = ({ handleNewUserEmailSliderState }) => {
  const navigate = useNavigate();

  // FIREBASE 
  const { updateDocument, response: responseUpdateDocument } = useFirestore("users");

  const { document: dbPageNavigationDocuments, error: dbPageNavigationError } =
    useDocument("settings", "PageNavigations");

  const [emailNewUserSliderState, setEmailNewUserSliderState] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState("");
  const [isEmail, setIsEmail] = useState(""); // State for email
  const [password, setPassword] = useState(""); // State for password
  const [newUserDocId, setNewUserDocId] = useState(null);

  // Effect to detect changes in emailSliderState and pass the updated value to parent
  useEffect(() => {
    handleNewUserEmailSliderState(emailNewUserSliderState);
  }, [emailNewUserSliderState, handleNewUserEmailSliderState]);

  // CHECK USER AVAILBLE OR NOT USING EMAIL PASSWORD AUTH
  const handleEmailPasswordLogin = async () => {
    // Email Validation
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!isEmail) {
      setError("No email provided.");
      return;
    } else if (!emailRegex.test(isEmail)) {
      setError("Invalid email format.");
      return;
    }

    // Password Validation (Firebase requires at least 6 characters)
    if (!password) {
      setError("No password provided.");
      return;
    } else if (password.length < 6) {
      setError("Password must be at least 6 characters long.");
      return;
    }

    setIsLoading(true);

    handleSignUp(isEmail, password);
  };

  // HANDLE EMAIL PASSWORD SIGNUP
  const handleSignUp = async (email, password) => {
    try {
      const userCredential = await projectAuth.createUserWithEmailAndPassword(email, password);
      console.log("User successfully registered!");

      let imgUrl = "/assets/img/dummy_user.png";
      const userId = userCredential.user.uid;

      // Store the document ID in state
      setNewUserDocId(userId);

      // Add user data to Firestore
      await projectFirestore.collection("users").doc(userCredential.user.uid).set({
        online: true,
        displayName: "",
        fullName: "",
        gender: "",
        phoneNumber: "",
        email: email,
        city: "",
        address: "",
        country: "",
        countryCode: "",
        role: "customer",
        roles: ["customer"],
        photoURL: imgUrl,
        status: "active",
        createdAt: timestamp.fromDate(new Date()),
        lastLoginTimestamp: timestamp.fromDate(new Date()),
      });

      // Show new user slider
      setEmailPasswordSliderState(false);
      setEmailNewUserSliderState(true);
    } catch (error) {
      if (error.code === "auth/email-already-in-use") {
        console.log("User already exists! Sign In...");
        handleLogin(isEmail, password);
      } else {
        console.error("Error signing up:", error.message);
      }
    }
  };

  // HANDLE EMAIL PASSWORD LOGIN
  const handleLogin = async (email, password) => {
    setIsLoading(true);
    try {
      const userCredential = await projectAuth.signInWithEmailAndPassword(email, password);
      console.log("User Logged In:", userCredential.user);

      // Update last login timestamp in Firestore
      await projectFirestore.collection("users").doc(userCredential.user.uid).update({
        online: true,
        lastLoginTimestamp: timestamp.fromDate(new Date()),
      });

      // Redirect to profile after login
      navigate("/profile");
      setIsLoading(false);
    } catch (error) {
      console.error("Error logging in:", error.message);
      setIsLoading(false);
      alert(error.message);
    }
  };

  // IF USER IS NEW
  const [genderSelectionSliderState, setGenderSelectionSliderState] = useState(true);
  const [newUserDetailsSliderState, setNewUserDetailsSliderState] = useState(false);
  const [selectedGender, setSelectedGender] = useState("");
  const [emailPasswordSliderState, setEmailPasswordSliderState] = useState(true);
  const [name, setName] = useState("");
  const [phoneNumber, setPhone] = useState("");
  const [city, setCity] = useState("");

  // GENDER SELECTION START
  const handleGenderSelect = (gender) => {
    setIsLoading(false);
    setSelectedGender(gender);
    setGenderSelectionSliderState(false);
    setNewUserDetailsSliderState(true);
  };
  // GENDER SELECTION END

  const newUserForm = async () => {
    if (!newUserDocId) {
      console.error("No document ID found. Cannot update user.");
      return;
    }

    // Basic validations
    if (!name.trim()) {
      setError("Name is required.");
      return;
    }
    if (!phoneNumber.trim()) {
      setError("Phone number is required.");
      return;
    }
    if (!city.trim()) {
      setError("City is required.");
      return;
    }

    // Phone validation (Example: Must start with + and have 10-15 digits)
    const phoneRegex = /^(?:\+91|0|91)?[6-9]\d{9}$/;
    if (!phoneRegex.test(phoneNumber)) {
      setError("Invalid phone number format.");
      return;
    }

    setEmailPasswordSliderState(false);
    setGenderSelectionSliderState(false);
    setIsLoading(true);

    let splitName = name.split(" ");
    let displayName = splitName.length > 0 ? splitName[0] : name;

    await updateDocument(newUserDocId, {
      displayName: camelCase(displayName.toLowerCase()),
      fullName: camelCase(name.toLowerCase()),
      phoneNumber,
      gender: selectedGender,
      city: camelCase(city.toLowerCase()),
    });

    if (dbPageNavigationDocuments) {
      if (dbPageNavigationDocuments && dbPageNavigationDocuments.afterLogin === "") {
        navigate("/profile");
      } else {
        navigate(dbPageNavigationDocuments && dbPageNavigationDocuments.afterLogin);
      }
    } else {
      navigate("/profile");
    }
    setNewUserDetailsSliderState(false);
    setEmailNewUserSliderState(false);
    setIsLoading(false);
  };

  return (
    <div>
      <div>
        {emailPasswordSliderState && (
          <>
            <div className="d-flex justify-content-center align-items-center">
              <h5 className="mt-4 mb-2">Login/Signup with Email</h5>
            </div>
            <div className="new_form_field with_icon phoneinput">
              <div>
                <TextField
                  label="Email"
                  id="email"
                  type="email"
                  value={isEmail}
                  onChange={(e) => setIsEmail(e.target.value)} // Update setter to setIsEmail
                  required
                  fullWidth
                  margin="normal"
                />

                {/* Password Input */}
                <TextField
                  label="Password"
                  id="password"
                  type="password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  required
                  fullWidth
                  margin="normal"
                />
                {error && (
                  <Alert variant="danger" className="mt-2">
                    {error}
                  </Alert>
                )}
              </div>
            </div>

            <div className="">
              {!isLoading ? (
                <>
                  <div className="d-flex justify-content-center flex-column">
                    <button
                      className="btn_fill"
                      onClick={handleEmailPasswordLogin}

                    >
                      Confirm
                    </button>
                  </div>
                </>
              ) :
                <div className="text-center">
                  <BeatLoader
                    color={"var(--click-color)"}
                    loading={true}
                  />
                </div>}
            </div>
          </>
        )}
      </div>

      {/* IF USER IS NEW  */}
      {emailNewUserSliderState && (
        <div>
          <div className="">
            {/* START GENDER SELECTION SLIDER */}
            <div>
              {genderSelectionSliderState && (
                <div className="login-gender-selection-slider">
                  <h5 className="mb-4">Select Your Gender</h5>
                  <div className="login-gender-options">
                    <div
                      className={`login-gender-option ${selectedGender === "male"
                        ? "login-gender-selected"
                        : ""
                        }`}
                      onClick={() => handleGenderSelect("male")}
                    >
                      <img
                        src="assets/img/men-icon-login.png"
                        alt="Male"
                      />
                      {selectedGender === "male" && (
                        <div className="login-gender-tick">
                          &#10003;
                        </div>
                      )}
                      <p>Male</p>
                    </div>
                    <div
                      className={`login-gender-option ${selectedGender === "female"
                        ? "login-gender-selected"
                        : ""
                        }`}
                      onClick={() =>
                        handleGenderSelect("female")
                      }
                    >
                      <img
                        src="assets/img/women-icon-login.png"
                        alt="Female"
                      />
                      {selectedGender === "female" && (
                        <div className="login-gender-tick">
                          &#10003;
                        </div>
                      )}
                      <p>Female</p>
                    </div>
                  </div>
                </div>
              )}
            </div>
            {/* END GENDER SELECTION SLIDER */}

            {newUserDetailsSliderState && (
              <>
                <Box
                  component="form"
                  sx={{
                    "& .MuiTextField-root": {
                      m: 1,
                      width: "95%",
                      textAlign: "center",
                    },
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    padding: "20px",
                    borderRadius: "8px",
                  }}
                  noValidate
                  autoComplete="on"
                >
                  <TextField
                    label={"Full Name"}
                    id="margin-none"
                    onChange={(e) => setName(e.target.value)}
                    value={name}
                    required
                  />
                  <TextField
                    label={"Phone"}
                    id="margin-none"
                    onChange={(e) => setPhone(e.target.value)}
                    value={phoneNumber}
                    required
                  />
                  <TextField
                    label={"Current City"}
                    id="margin-none"
                    onChange={(e) => setCity(e.target.value)}
                    value={city}
                    required
                  />
                </Box>

                {error && (
                  <Alert variant="danger" className="mt-2">
                    {error}
                  </Alert>
                )}

                {isLoading ? (
                  <div className="text-center">
                    <BeatLoader
                      color={"var(--click-color)"}
                      loading={true}
                    />
                  </div>
                ) :
                  <div className="d-flex justify-content-center mt-2">
                    <button
                      className="btn_fill"
                      onClick={newUserForm}
                    >
                      Done
                    </button>
                  </div>}
              </>
            )}
          </div>
        </div>
      )}
    </div>
  )
}

export default EmailPasswordLoginSignup
